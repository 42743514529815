<template>
  <div>
    <TableRittenRendabiliteitTemplate :title="`Rendabiliteit - ${dateStringLocale(date)}`" :date="date" />
  </div>
</template>

<script setup>
import { defineAsyncComponent, computed } from 'vue'
import { useRoute } from 'vue-router'

import { dateStringLocale } from '@/functions/formatDate'

const TableRittenRendabiliteitTemplate = defineAsyncComponent(() => import('@/components/Table/Ritten/RendabiliteitTemplate.vue'))

const route = useRoute()

const date = computed(() => route.query.date)
</script>

